fieldset {
    display: none;
}

fieldset:first-child {
    display: block;
}

#progressbar { 
	margin-bottom: 30px; 
	overflow: hidden; 
	color: lightgrey 
} 

#progressbar .active { 
	color: #2F8D46
} 

#progressbar li { 
	list-style-type: none; 
	font-size: 15px; 
	width: 11%; 
	float: left; 
	position: relative; 
	font-weight: 400
} 

#progressbar #prog1:before { 
	content: "1"
} 

#progressbar #prog2:before { 
	content: "2"
} 

#progressbar #prog3:before { 
	content: "3"
} 

#progressbar #prog4:before { 
	content: "4"
} 

#progressbar #prog5:before { 
	content: "5"
} 

#progressbar #prog6:before { 
	content: "6"
} 

#progressbar #prog7:before { 
	content: "7"
} 

#progressbar #prog8:before { 
	content: "8"
} 
#progressbar #prog9:before { 
	content: "9"
} 

#progressbar li:before { 
	width: 50px; 
	height: 50px; 
	line-height: 45px; 
	display: block; 
	font-size: 20px; 
	color: #ffffff; 
	background: lightgray; 
	border-radius: 50%; 
	margin: 0 auto 10px auto; 
	padding: 2px
} 

#progressbar li:after { 
	content: ''; 
	width: 100%; 
	height: 2px; 
	background: lightgray; 
	position: absolute; 
	left: 0; 
	top: 25px; 
	z-index: -1
} 

#progressbar li.active:before, 
#progressbar li.active:after { 
	background: #2F8D46
} 

.progress { 
	height: 10px
} 

.progress-bar { 
	background-color: #2F8D46
}


.flous-bg-primary {
	background-color: #004AAD;
	color: #ffffff;
}

.flous-bg-menu {
	background-color:  #235CBE;
	color: #ffffff;
}

.flous-bg-topmenu {
	background-color:  #e1f1ff;
	color: #000000;
}

.flous-bg-secondary {
	background-color:  #95B6E1;
	color: #ffffff;
}

.flous-bg-thirty {
	background-color:  #7FA1F824;
	color: #000000;
}

.flous-input-color {
	color: #004AAD;
}

.flous-input-color::placeholder {
	color: #004AAD;
}

.flous-btn-gradient {
	background: linear-gradient(to right, white, #95B6E1, #95B6E1);
	color: white; /* Si tu veux que le texte soit blanc */
	border: none; /* Pour enlever les bordures par défaut */
	transition: background 0.3s ease; /* Pour une transition douce au survol */
  }
  
.flous-btn-gradient:hover {
	background: linear-gradient(to right, #95B6E1, #004AAD, #004AAD);
}

.flous-btn-thirty {
	background: linear-gradient(to right, white, #303336, #13151824);
	color: white; /* Si tu veux que le texte soit blanc */
	border: none; /* Pour enlever les bordures par défaut */
	transition: background 0.3s ease; /* Pour une transition douce au survol */
  }
  
.flous-btn-thirty:hover {
	background: linear-gradient(to right, #dadfe6, #505458, #1a1b1d);
	color: #ffffff;
}

.flous-btn-primary {
	background: linear-gradient(to right, #95B6E1, #004AAD, #004AAD);
	color: white; /* Si tu veux que le texte soit blanc */
	border: none; /* Pour enlever les bordures par défaut */
	transition: background 0.3s ease; /* Pour une transition douce au survol */
}
  
.flous-btn-primary:hover {
	background: linear-gradient(to right, white, #004AAD, #95B6E1);
	color: #ffffff;
}
  
.flous-tabs {
	display: flex;
	justify-content: left;
	background-color: #fff;
	border-bottom: 2px solid #ddd;
  }
  
  .flous-tab {
	padding: 15px 20px;
	cursor: pointer;
	transition: color 0.3s;
	position: relative;
  }
  
  .flous-tab:hover {
	color: #007BFF;
  }
  
  .flous-tab.flous-active {
	color: #007BFF;
	border-bottom: 3px solid #007BFF;
  }
  
  .flous-tab-content {
	padding: 20px;
	background-color: #fff;
	border: 1px solid #ddd;
	margin-top: -1px;
  }
  
  .flous-content {
	display: none;
  }
  
  .flous-content.flous-active {
	display: block;
  }

  .sidebar-footer {
	position: absolute;
	bottom: 0;
	width: 97%;
	padding: 10px;
  }

.error {
	color: red;
	font-size: 0.9em;
	margin-top: 5px;
}

.phone-field {
	width: 100%;
}


/* Ajoute de code 13 / 07 / 2024 */
.flous-border-radius {
	border-radius: '50px';
}
.flous-top-header-border-radius {
	border-radius: '50px';
}

.flous-content {
	background-image: url('https://i.pinimg.com/564x/c6/68/7c/c6687c32a971452e9bd81746f44d13c0.jpg');
	background-size: cover; /* Ajuste l'image pour couvrir l'ensemble de l'élément */
	background-position: center; /* Centre l'image */
	min-height: 100vh; /* Assure que l'élément couvre toute la hauteur de la vue */
  }

.bordered {
	padding: 0 15px; /* Ajoute un léger espace intérieur */
	border-right: 1px solid #ccc; /* Bordure droite grise */
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Mettre l'overlay au-dessus de tout */
}

/* Styles pour le spinner */
.spinner {
    border: 8px solid rgba(255, 255, 255, 0.3); /* Couleur de la bordure extérieure */
    border-top: 8px solid white; /* Couleur de la bordure supérieure pour l'animation */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite; /* Animation du spinner */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-video {
    width: 150px; /* Ajustez la taille du logo vidéo selon vos besoins */
    height: auto;
    object-fit: contain; /* Maintient le ratio de l'aspect du logo */
    pointer-events: none; /* Empêche l’interaction utilisateur avec la vidéo */
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5); /* Fond gris semi-transparent */
	z-index: 1040; /* Derrière le modal */
  }

  @keyframes fadeInUp {
	from {
	  opacity: 0;
	  transform: translateY(30px); /* Départ légèrement en bas */
	}
	to {
	  opacity: 1;
	  transform: translateY(0);
	}
  }


