.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* src/App.css */
@font-face {
  font-family: 'flous-police-one';
  src: url('./assets/fonts/police_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'flous-police-two';
  src: url('./assets/fonts/police_2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.flous-police-one {
  font-family: 'flous-police-one', sans-serif;
}

.flous-police-two {
  font-family: 'flous-police-two', sans-serif;
}
