.scrolling-container {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    background-color: #235CBE;
    padding: 10px 0;
    margin-top: 10px;
  }
  
  .scrolling-message {
    display: inline-block;
    white-space: nowrap;
    animation: scroll-right-left 50s linear infinite;
    color: white;
    font-size:medium;
  }
  
  .scrolling-message span {
    display: inline-block;
    flex: none;
    padding-right: 100%; /* Space between repetitions */
  }
  
  @keyframes scroll-right-left {
    0% {
      transform: translateX(100%); /* Starts outside the screen on the right */
    }
    100% {
      transform: translateX(-100%); /* Moves completely to the left */
    }
  }
  
  .scrolling-message:hover {
    animation-play-state: paused;
  }
  